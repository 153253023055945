<style>
  .page-footer {
    padding-top: 0px;
  }
</style>

<footer class="page-footer teal">
  <div class="footer-copyright">
    <div class="container">
      Made by
      <a href="https://www.alvareznavarro.es" title="Web developer" class="brown-text text-lighten-3">Jorge Alvarez</a>
      |
      <a
        href="https://github.com/jorgegorka/svelte-firebase"
        title="Download YouFix"
        class="brown-text text-lighten-3">
        Download the template
      </a>
      | All product names, logos, and brands are property of their respective owners
    </div>
  </div>
</footer>
