<style>
  .logo-image {
    height: 50px;
  }
</style>

<div class="container">
  <!-- <h2 class="center brown-text">Amazing technologies already included</h2> -->
  <section class="section">
    <div class="row">
      <div class="col s12 m6">
        <div class="icon-block">
          <div class="center">
            <a href="https://svelte.dev/" title="Svelte Cibernetically enhanced web apps">
              <img src="/images/svelte-logo.svg" class="logo-image" alt="Svelte" />
            </a>
          </div>
          <p class="light">
            Build boilerplate-free components using languages you already know like HTML, CSS and JavasScript.
          </p>
        </div>
      </div>
      <div class="col s12 m6">
        <div class="icon-block">
          <div class="center">
            <a href="https://firebase.google.com/" title="Google Firebase">
              <img src="/images/firebase-logo.png" class="logo-image" alt="Firebase" />
            </a>
          </div>
          <p class="light">
            Database, hosting, authentication, cloud functions, storage, analytics, in-app messaging, performance
            monitoring and more.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col s12 m4">
        <div class="icon-block">
          <div class="center">
            <a href="https://github.com/jorgegorka/svelte-router" title="A svelte router component">
              <img src="/images/svelte-router-logo.png" class="logo-image" alt="Svelte Routing" />
            </a>
          </div>
          <p class="light">A router for Svelte and your single page applications.</p>
        </div>
      </div>
      <div class="col s12 m4">
        <div class="icon-block">
          <div class="center">
            <a href="https://validatejs.org/" title="Validate.js validates javascript objects">

              <img src="/images/validate-js-logo.png" class="logo-image" alt="Validate.js" />

            </a>
          </div>
          <p class="light">A declarative way of validating javascript objects.</p>
        </div>
      </div>
      <div class="col s12 m4">
        <div class="icon-block">
          <div class="center">
            <a href="https://materializecss.com/" title="Materialize CSS responsive front-end framework">
              <img src="/images/materialize.svg" class="logo-image" alt="Materialize css" />
            </a>
          </div>
          <p class="light">Materialize CSS A modern responsive front-end framework based on Material Design.</p>
        </div>
      </div>
    </div>
  </section>
</div>
